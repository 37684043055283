<template>
  <section
    class="bg-primary w-full navbar inset-x z-50"
    :class="{ overlay: scrolled }"
  >
    <div
      :class="{ padding: scrolled }"
      class="w-[95%] md:w-[85%] flex mx-auto justify-between items-center py-6"
    >
      <router-link to="/">
        <svg
          version="1.1"
          id="Layer_1"
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
          x="0px"
          :class="{ img: scrolled }"
          class="md:h-[60px] w-[120px] sm:w-[150px] xl:w-[200px] logo"
          y="0px"
          viewBox="0 0 241 73.75"
          enable-background="new 0 0 241 73.75"
          xml:space="preserve"
        >
          <g>
            <g>
              <linearGradient
                id="SVGID_1_"
                gradientUnits="userSpaceOnUse"
                x1="471.1621"
                y1="79.5059"
                x2="426.5833"
                y2="32.0331"
                gradientTransform="matrix(-1 0 0 1 484.9648 0)"
              >
                <stop offset="0" style="stop-color: #8e26b7" />
                <stop offset="1" style="stop-color: #ef4bfb" />
              </linearGradient>
              <path
                fill="url(#SVGID_1_)"
                d="M79.49,50.995c0,0-8.63-16.897-43.88-14.544c-5.399,0.362-22.651,2.511-22.651,2.511l-6.98,14.174
			c0,0-8.084,12.103-3.651,17.122c0,0,4.78,5.563,15.386-0.538l19.729-13.003C37.443,56.717,61.729,43.065,79.49,50.995z"
              />
              <g>
                <path
                  opacity="0.07"
                  fill="#231F20"
                  d="M5.979,53.136l-1.715,2.957c0,0,13.236-7.94,30.058,2.76l2.717-1.87
				C37.04,56.982,18.672,43.62,5.979,53.136z"
                />
                <path
                  opacity="0.07"
                  fill="#231F20"
                  d="M64.348,39.795c0,0-3.561-1.288-5.321-1.725l4.304,11.134c0,0-0.686-0.706,4.758-0.674
				L64.348,39.795z"
                />
              </g>
            </g>
            <linearGradient
              id="SVGID_2_"
              gradientUnits="userSpaceOnUse"
              x1="13.252"
              y1="19.3672"
              x2="68.7251"
              y2="67.3909"
            >
              <stop offset="0" style="stop-color: #fd6d17" />
              <stop offset="1" style="stop-color: #fd2957" />
            </linearGradient>
            <path
              fill="url(#SVGID_2_)"
              d="M5.474,54.026l23.862-48.03c0,0,2.358-4.36,7.301-4.709c5.399-0.383,8.125,4.696,8.125,4.696
		l24.211,48.043c0,0,7.212,11.212,2.779,16.231c0,0-4.78,5.563-15.386-0.538L36.637,56.717
		C36.637,56.717,15.338,44.789,5.474,54.026z"
            />
          </g>
          <path
            fill="#FFFFFF"
            d="M90.012,32.131c-0.623,0-1.102-0.277-1.436-0.827c-0.333-0.551-0.366-1.138-0.1-1.757l9.38-23.11
	c0.333-0.804,0.878-1.205,1.635-1.205c0.801,0,1.346,0.401,1.635,1.205l9.414,23.178c0.245,0.645,0.206,1.223-0.117,1.741
	c-0.322,0.516-0.807,0.774-1.452,0.774c-0.334,0-0.65-0.097-0.951-0.294c-0.3-0.193-0.519-0.464-0.651-0.81L99.091,9.812h0.935
	l-8.413,21.215c-0.156,0.369-0.383,0.644-0.684,0.826C90.63,32.039,90.324,32.131,90.012,32.131z M91.781,26.205l1.335-2.959h13.085
	l1.335,2.959H91.781z"
          />
          <path
            fill="#FFFFFF"
            d="M118.518,32.131c-0.98,0-1.847-0.277-2.604-0.827c-0.756-0.551-1.346-1.302-1.769-2.257
	c-0.423-0.951-0.635-2.048-0.635-3.289V6.953c0-0.504,0.156-0.917,0.469-1.24c0.311-0.322,0.711-0.481,1.201-0.481
	c0.489,0,0.889,0.159,1.202,0.481c0.312,0.323,0.467,0.735,0.467,1.24v18.805c0,0.851,0.155,1.553,0.467,2.102
	c0.312,0.552,0.712,0.826,1.202,0.826h0.834c0.446,0,0.807,0.16,1.085,0.482c0.277,0.323,0.417,0.734,0.417,1.241
	c0,0.505-0.212,0.916-0.634,1.239c-0.423,0.321-0.968,0.482-1.636,0.482H118.518z"
          />
          <path
            fill="#FFFFFF"
            d="M130.802,32.268c-1.67,0-3.144-0.407-4.423-1.222c-1.28-0.813-2.288-1.941-3.021-3.377
	c-0.734-1.433-1.101-3.081-1.101-4.942c0-1.88,0.395-3.54,1.186-4.976c0.789-1.436,1.873-2.56,3.254-3.375
	c1.379-0.814,2.97-1.224,4.772-1.224c1.781,0,3.36,0.41,4.74,1.224c1.38,0.815,2.463,1.939,3.255,3.375
	c0.789,1.436,1.187,3.096,1.187,4.976h-1.971c0,1.861-0.34,3.51-1.02,4.942c-0.68,1.436-1.607,2.563-2.786,3.377
	C133.695,31.86,132.337,32.268,130.802,32.268z M131.401,39.948c-1.578,0-3.059-0.323-4.438-0.964
	c-1.379-0.644-2.548-1.584-3.505-2.824c-0.29-0.392-0.395-0.792-0.317-1.206c0.078-0.413,0.294-0.746,0.651-0.998
	c0.401-0.276,0.818-0.368,1.252-0.276c0.434,0.092,0.784,0.321,1.052,0.689c0.601,0.781,1.355,1.39,2.269,1.826
	c0.912,0.435,1.948,0.653,3.104,0.653c1.068,0,2.054-0.264,2.954-0.791c0.902-0.527,1.618-1.31,2.153-2.343
	c0.533-1.034,0.801-2.297,0.801-3.789v-4.889l1.101-3.032l2.173,0.722v7.439c0,1.815-0.391,3.462-1.169,4.944
	c-0.781,1.479-1.865,2.657-3.255,3.528C134.836,39.514,133.227,39.948,131.401,39.948z M131.469,29.168
	c1.155,0,2.181-0.274,3.07-0.826c0.891-0.552,1.585-1.311,2.087-2.273c0.501-0.965,0.751-2.077,0.751-3.342
	c0-1.264-0.25-2.38-0.751-3.358c-0.502-0.972-1.196-1.738-2.087-2.29c-0.89-0.55-1.915-0.826-3.07-0.826
	c-1.156,0-2.181,0.276-3.07,0.826c-0.892,0.552-1.592,1.318-2.104,2.29c-0.512,0.979-0.767,2.095-0.767,3.358
	c0,1.265,0.255,2.377,0.767,3.342c0.512,0.963,1.212,1.722,2.104,2.273C129.288,28.894,130.313,29.168,131.469,29.168z"
          />
          <path
            fill="#FFFFFF"
            d="M154,32.268c-1.802,0-3.395-0.407-4.773-1.222c-1.38-0.813-2.466-1.941-3.255-3.377
	c-0.79-1.433-1.185-3.081-1.185-4.942c0-1.88,0.395-3.54,1.185-4.976c0.789-1.436,1.875-2.56,3.255-3.375
	c1.379-0.814,2.972-1.224,4.773-1.224c1.779,0,3.36,0.41,4.74,1.224c1.379,0.815,2.465,1.939,3.254,3.375
	c0.79,1.436,1.185,3.096,1.185,4.976c0,1.861-0.39,3.51-1.168,4.942c-0.777,1.436-1.857,2.563-3.236,3.377
	C157.394,31.86,155.802,32.268,154,32.268z M154,29.168c1.156,0,2.18-0.274,3.07-0.826c0.89-0.552,1.587-1.311,2.087-2.273
	c0.501-0.965,0.751-2.077,0.751-3.342c0-1.264-0.25-2.38-0.751-3.358c-0.5-0.972-1.197-1.738-2.087-2.29
	c-0.891-0.55-1.914-0.826-3.07-0.826s-2.181,0.276-3.07,0.826c-0.891,0.552-1.593,1.318-2.104,2.29
	c-0.513,0.979-0.769,2.095-0.769,3.358c0,1.265,0.256,2.377,0.769,3.342c0.511,0.963,1.213,1.722,2.104,2.273
	C151.819,28.894,152.844,29.168,154,29.168z"
          />
          <path
            fill="#FFFFFF"
            d="M174.896,32.268c-1.561,0-3-0.244-4.324-0.74c-1.324-0.494-2.353-1.118-3.088-1.876
	c-0.332-0.367-0.472-0.786-0.417-1.257c0.056-0.473,0.272-0.855,0.652-1.154c0.444-0.367,0.883-0.522,1.317-0.467
	c0.434,0.06,0.807,0.258,1.119,0.604c0.377,0.437,0.983,0.844,1.818,1.223c0.834,0.379,1.763,0.568,2.787,0.568
	c1.29,0,2.275-0.218,2.954-0.653c0.679-0.437,1.029-0.999,1.052-1.689c0.022-0.688-0.295-1.285-0.952-1.788
	c-0.655-0.505-1.852-0.921-3.587-1.243c-2.249-0.458-3.878-1.146-4.891-2.063c-1.014-0.92-1.52-2.045-1.52-3.378
	c0-1.17,0.335-2.139,1.002-2.908c0.668-0.77,1.523-1.345,2.57-1.724c1.046-0.379,2.136-0.568,3.271-0.568
	c1.469,0,2.771,0.241,3.906,0.725c1.135,0.48,2.036,1.148,2.703,1.997c0.313,0.369,0.456,0.754,0.435,1.156
	c-0.022,0.399-0.212,0.738-0.567,1.015c-0.356,0.252-0.779,0.332-1.269,0.239c-0.49-0.09-0.9-0.298-1.235-0.618
	c-0.556-0.552-1.157-0.931-1.802-1.137c-0.647-0.208-1.392-0.31-2.236-0.31c-0.98,0-1.809,0.173-2.488,0.516
	c-0.678,0.344-1.018,0.85-1.018,1.516c0,0.414,0.105,0.787,0.317,1.118c0.21,0.334,0.628,0.633,1.252,0.898
	c0.623,0.263,1.534,0.52,2.736,0.774c1.67,0.343,2.988,0.781,3.956,1.306c0.967,0.53,1.662,1.145,2.087,1.847
	c0.422,0.697,0.635,1.508,0.635,2.427c0,1.056-0.273,2.009-0.818,2.858c-0.546,0.851-1.347,1.527-2.403,2.031
	C177.792,32.016,176.475,32.268,174.896,32.268z"
          />
          <path
            fill="#FFFFFF"
            d="M194.655,32.268c-1.802,0-3.394-0.407-4.774-1.222c-1.379-0.813-2.464-1.941-3.253-3.377
	c-0.791-1.433-1.187-3.081-1.187-4.942c0-1.88,0.396-3.54,1.187-4.976c0.789-1.436,1.874-2.56,3.253-3.375
	c1.381-0.814,2.973-1.224,4.774-1.224c1.779,0,3.359,0.41,4.74,1.224c1.38,0.815,2.465,1.939,3.253,3.375
	c0.79,1.436,1.187,3.096,1.187,4.976c0,1.861-0.391,3.51-1.169,4.942c-0.778,1.436-1.857,2.563-3.237,3.377
	C198.048,31.86,196.458,32.268,194.655,32.268z M194.655,29.168c1.156,0,2.18-0.274,3.07-0.826s1.587-1.311,2.086-2.273
	c0.501-0.965,0.752-2.077,0.752-3.342c0-1.264-0.251-2.38-0.752-3.358c-0.499-0.972-1.195-1.738-2.086-2.29
	c-0.891-0.55-1.914-0.826-3.07-0.826c-1.157,0-2.181,0.276-3.071,0.826c-0.891,0.552-1.59,1.318-2.103,2.29
	c-0.512,0.979-0.768,2.095-0.768,3.358c0,1.265,0.256,2.377,0.768,3.342c0.513,0.963,1.212,1.722,2.103,2.273
	S193.498,29.168,194.655,29.168z"
          />
          <path
            fill="#FFFFFF"
            d="M210.61,32.163c-0.49,0-0.896-0.165-1.218-0.498c-0.323-0.333-0.484-0.751-0.484-1.256V15.046
	c0-0.528,0.161-0.952,0.484-1.274c0.321-0.32,0.728-0.481,1.218-0.481c0.512,0,0.924,0.161,1.233,0.481
	c0.313,0.322,0.469,0.746,0.469,1.274v15.363c0,0.505-0.156,0.923-0.469,1.256C211.534,31.998,211.122,32.163,210.61,32.163z
	M224.329,32.163c-0.489,0-0.896-0.165-1.218-0.498s-0.485-0.751-0.485-1.256v-8.474c0-1.311-0.232-2.378-0.7-3.205
	c-0.467-0.826-1.097-1.439-1.885-1.842c-0.791-0.4-1.697-0.602-2.722-0.602c-0.935,0-1.781,0.194-2.536,0.586
	c-0.757,0.39-1.358,0.913-1.804,1.566c-0.444,0.653-0.667,1.406-0.667,2.255h-2.103c0-1.444,0.339-2.738,1.018-3.874
	c0.68-1.137,1.608-2.037,2.786-2.702c1.181-0.666,2.504-1,3.973-1c1.536,0,2.91,0.338,4.123,1.016
	c1.212,0.677,2.169,1.67,2.871,2.979c0.7,1.31,1.05,2.916,1.05,4.822v8.474c0,0.505-0.161,0.923-0.482,1.256
	C225.225,31.998,224.818,32.163,224.329,32.163z"
          />
          <path
            fill="#FFFFFF"
            d="M99.325,69.5c-1.425,0-2.782-0.23-4.073-0.688c-1.29-0.46-2.414-1.102-3.371-1.93
	c-0.957-0.826-1.658-1.778-2.103-2.856c-0.223-0.485-0.217-0.932,0.017-1.344c0.233-0.415,0.627-0.677,1.185-0.794
	c0.399-0.089,0.794-0.027,1.184,0.189c0.389,0.219,0.685,0.547,0.885,0.983c0.267,0.573,0.706,1.101,1.318,1.583
	c0.612,0.482,1.352,0.873,2.22,1.171c0.868,0.299,1.78,0.448,2.737,0.448c1.068,0,2.053-0.174,2.954-0.518
	c0.901-0.343,1.63-0.842,2.187-1.498c0.556-0.654,0.834-1.464,0.834-2.428c0-1.217-0.461-2.307-1.385-3.272
	c-0.923-0.964-2.442-1.562-4.556-1.791c-2.759-0.275-4.929-1.113-6.509-2.513c-1.581-1.401-2.37-3.136-2.37-5.2
	c0-1.517,0.411-2.802,1.235-3.857c0.823-1.057,1.941-1.862,3.354-2.412c1.412-0.552,2.987-0.825,4.724-0.825
	c1.357,0,2.542,0.193,3.554,0.585c1.012,0.39,1.897,0.929,2.654,1.616c0.756,0.689,1.413,1.507,1.97,2.446
	c0.312,0.504,0.439,0.98,0.383,1.431c-0.055,0.448-0.273,0.795-0.651,1.049c-0.423,0.275-0.879,0.335-1.368,0.174
	c-0.489-0.162-0.857-0.46-1.102-0.896c-0.356-0.667-0.791-1.229-1.302-1.688c-0.512-0.459-1.114-0.819-1.803-1.084
	c-0.69-0.265-1.501-0.396-2.437-0.396c-1.646-0.024-3.027,0.291-4.138,0.946c-1.113,0.654-1.67,1.657-1.67,3.013
	c0,0.689,0.173,1.35,0.518,1.98c0.344,0.632,0.979,1.176,1.902,1.636c0.923,0.46,2.253,0.781,3.989,0.964
	c2.648,0.277,4.711,1.108,6.192,2.498c1.479,1.39,2.22,3.221,2.22,5.492c0,1.31-0.262,2.446-0.785,3.411
	c-0.523,0.963-1.224,1.772-2.103,2.426c-0.879,0.656-1.881,1.145-3.004,1.467C101.689,69.34,100.526,69.5,99.325,69.5z"
          />
          <path
            fill="#FFFFFF"
            d="M121.956,69.294c-1.802,0-3.394-0.408-4.772-1.223c-1.38-0.815-2.465-1.942-3.255-3.377
	c-0.79-1.433-1.185-3.082-1.185-4.942c0-1.88,0.395-3.541,1.185-4.977c0.79-1.434,1.875-2.56,3.255-3.374
	c1.379-0.813,2.97-1.225,4.772-1.225c1.779,0,3.36,0.411,4.741,1.225c1.379,0.814,2.464,1.94,3.255,3.374
	c0.789,1.436,1.184,3.097,1.184,4.977c0,1.86-0.391,3.51-1.168,4.942c-0.778,1.435-1.858,2.562-3.238,3.377
	C125.349,68.886,123.758,69.294,121.956,69.294z M121.956,66.193c1.157,0,2.182-0.273,3.071-0.827
	c0.889-0.551,1.584-1.309,2.086-2.272c0.5-0.965,0.752-2.077,0.752-3.342c0-1.263-0.252-2.38-0.752-3.358
	c-0.502-0.973-1.197-1.738-2.086-2.291c-0.889-0.55-1.914-0.826-3.071-0.826c-1.157,0-2.181,0.276-3.07,0.826
	c-0.891,0.553-1.591,1.318-2.103,2.291c-0.513,0.979-0.768,2.096-0.768,3.358c0,1.265,0.255,2.377,0.768,3.342
	c0.512,0.964,1.212,1.722,2.103,2.272C119.774,65.92,120.799,66.193,121.956,66.193z"
          />
          <path
            fill="#FFFFFF"
            d="M135.107,53.966c-0.468,0-0.846-0.138-1.135-0.413c-0.29-0.276-0.434-0.618-0.434-1.034
	c0-0.456,0.144-0.825,0.434-1.101c0.289-0.274,0.667-0.416,1.135-0.416h8.245c0.466,0,0.845,0.142,1.134,0.416
	c0.29,0.275,0.434,0.645,0.434,1.101c0,0.416-0.144,0.758-0.434,1.034c-0.289,0.275-0.668,0.413-1.134,0.413H135.107z
	M138.18,69.156c-0.426,0-0.803-0.161-1.136-0.483c-0.334-0.321-0.501-0.746-0.501-1.273V50.418c0-1.653,0.316-3.088,0.95-4.305
	c0.635-1.216,1.521-2.162,2.655-2.841c1.134-0.676,2.447-1.015,3.938-1.015c0.49,0,0.896,0.148,1.219,0.446
	c0.322,0.299,0.482,0.679,0.482,1.138c0,0.437-0.16,0.808-0.482,1.117c-0.322,0.311-0.729,0.465-1.219,0.465
	c-0.823,0-1.546,0.197-2.171,0.587c-0.621,0.392-1.105,0.929-1.45,1.618c-0.346,0.688-0.519,1.503-0.519,2.446v17.324
	c0,0.527-0.155,0.952-0.466,1.273C139.168,68.995,138.735,69.156,138.18,69.156z"
          />
          <path
            fill="#FFFFFF"
            d="M147.69,53.966c-0.467,0-0.847-0.138-1.134-0.413c-0.289-0.276-0.435-0.618-0.435-1.034
	c0-0.456,0.146-0.825,0.435-1.101c0.287-0.274,0.667-0.416,1.134-0.416h7.713c0.465,0,0.845,0.142,1.134,0.416
	c0.29,0.275,0.433,0.645,0.433,1.101c0,0.416-0.143,0.758-0.433,1.034c-0.289,0.275-0.669,0.413-1.134,0.413H147.69z
	M155.102,69.156c-1.112,0-2.114-0.294-3.003-0.879c-0.892-0.584-1.594-1.384-2.104-2.395c-0.512-1.008-0.768-2.145-0.768-3.41
	V46.044c0-0.505,0.155-0.916,0.468-1.237c0.311-0.322,0.712-0.484,1.2-0.484c0.49,0,0.892,0.162,1.204,0.484
	c0.309,0.321,0.466,0.732,0.466,1.237v16.429c0,0.918,0.245,1.688,0.733,2.31c0.492,0.62,1.09,0.929,1.804,0.929h1.134
	c0.402,0,0.739,0.16,1.02,0.482c0.277,0.322,0.418,0.733,0.418,1.24c0,0.505-0.184,0.918-0.552,1.239
	c-0.367,0.322-0.839,0.483-1.418,0.483H155.102z"
          />
          <path
            fill="#FFFFFF"
            d="M166.049,69.156c-0.355,0-0.684-0.108-0.983-0.327c-0.301-0.219-0.507-0.488-0.617-0.812l-4.941-15.29
	c-0.178-0.713-0.173-1.293,0.017-1.742c0.189-0.446,0.606-0.669,1.252-0.669c0.422,0,0.774,0.122,1.052,0.361
	c0.277,0.24,0.505,0.647,0.686,1.224l4.204,13.569h-1.169l3.974-13.847c0.11-0.388,0.3-0.706,0.566-0.946
	c0.269-0.239,0.636-0.361,1.103-0.361c0.444,0,0.806,0.122,1.084,0.361c0.278,0.24,0.461,0.559,0.552,0.946l3.238,13.02h-0.502
	l3.938-12.742c0.313-1.057,0.891-1.585,1.737-1.585c0.622,0,1.044,0.223,1.267,0.669c0.223,0.449,0.212,1.029-0.032,1.742
	l-4.94,15.29c-0.088,0.323-0.278,0.593-0.568,0.812c-0.288,0.219-0.622,0.327-1.001,0.327c-0.356,0-0.69-0.108-1-0.327
	c-0.313-0.219-0.515-0.488-0.603-0.812l-3.438-13.121h0.334l-3.605,13.121c-0.089,0.323-0.283,0.593-0.584,0.812
	C166.768,69.048,166.427,69.156,166.049,69.156z"
          />
          <path
            fill="#FFFFFF"
            d="M193.085,69.294c-1.622,0-3.08-0.42-4.371-1.258c-1.291-0.839-2.31-1.977-3.054-3.41
	c-0.746-1.434-1.119-3.06-1.119-4.874c0-1.812,0.407-3.444,1.22-4.891c0.812-1.444,1.913-2.589,3.305-3.427
	c1.389-0.838,2.954-1.258,4.688-1.258c1.737,0,3.295,0.42,4.674,1.258s2.476,1.982,3.288,3.427c0.813,1.446,1.219,3.079,1.219,4.891
	h-1.301c0,1.814-0.374,3.44-1.119,4.874c-0.747,1.434-1.764,2.571-3.054,3.41C196.168,68.874,194.711,69.294,193.085,69.294z
	M193.753,66.193c1.136,0,2.148-0.281,3.038-0.843c0.89-0.563,1.591-1.33,2.104-2.31c0.512-0.975,0.767-2.071,0.767-3.289
	c0-1.238-0.255-2.348-0.767-3.323c-0.513-0.976-1.214-1.745-2.104-2.307c-0.89-0.563-1.902-0.846-3.038-0.846
	c-1.112,0-2.118,0.282-3.02,0.846c-0.9,0.562-1.613,1.331-2.136,2.307c-0.524,0.976-0.785,2.085-0.785,3.323
	c0,1.218,0.261,2.314,0.785,3.289c0.522,0.979,1.235,1.746,2.136,2.31C191.635,65.912,192.641,66.193,193.753,66.193z
	M201.231,69.189c-0.489,0-0.896-0.165-1.218-0.501c-0.322-0.33-0.485-0.75-0.485-1.255v-5.271l0.636-3.648l2.77,1.238v7.682
	c0,0.505-0.161,0.925-0.483,1.255C202.127,69.024,201.721,69.189,201.231,69.189z"
          />
          <path
            fill="#FFFFFF"
            d="M209.676,69.156c-0.533,0-0.946-0.145-1.235-0.431s-0.433-0.718-0.433-1.292V52.039
	c0-0.552,0.144-0.977,0.433-1.275s0.702-0.447,1.235-0.447c0.557,0,0.973,0.142,1.252,0.429c0.277,0.288,0.417,0.72,0.417,1.294
	v15.395c0,0.551-0.14,0.977-0.417,1.275C210.648,69.008,210.232,69.156,209.676,69.156z M209.644,57.377
	c0-1.379,0.328-2.613,0.983-3.702c0.656-1.092,1.542-1.958,2.653-2.601c1.113-0.644,2.336-0.966,3.672-0.966
	s2.332,0.224,2.987,0.673c0.657,0.447,0.907,0.98,0.752,1.601c-0.066,0.321-0.195,0.568-0.384,0.741
	c-0.189,0.173-0.407,0.282-0.65,0.326c-0.247,0.048-0.513,0.037-0.802-0.032c-1.424-0.299-2.702-0.322-3.838-0.072
	c-1.135,0.254-2.032,0.726-2.688,1.415c-0.656,0.688-0.984,1.561-0.984,2.617H209.644z"
          />
          <path
            fill="#FFFFFF"
            d="M231.473,69.294c-1.849,0-3.489-0.408-4.924-1.223c-1.436-0.815-2.559-1.942-3.371-3.377
	c-0.813-1.433-1.22-3.082-1.22-4.942c0-1.88,0.384-3.541,1.153-4.977c0.767-1.434,1.83-2.56,3.188-3.374
	c1.356-0.813,2.915-1.225,4.672-1.225c1.736,0,3.228,0.398,4.474,1.188c1.246,0.794,2.198,1.883,2.854,3.273
	c0.655,1.39,0.983,2.991,0.983,4.805c0,0.437-0.145,0.798-0.434,1.086c-0.289,0.286-0.655,0.428-1.101,0.428h-13.453v-2.754h13.352
	l-1.368,0.963c-0.022-1.146-0.245-2.173-0.666-3.08c-0.424-0.908-1.024-1.625-1.804-2.152c-0.779-0.528-1.725-0.793-2.838-0.793
	c-1.268,0-2.354,0.289-3.253,0.861c-0.903,0.573-1.586,1.358-2.054,2.358c-0.467,1-0.7,2.129-0.7,3.392
	c0,1.265,0.277,2.39,0.834,3.377s1.323,1.767,2.304,2.342c0.978,0.572,2.103,0.859,3.371,0.859c0.689,0,1.396-0.129,2.12-0.396
	c0.722-0.263,1.306-0.568,1.752-0.911c0.334-0.253,0.694-0.386,1.084-0.397c0.388-0.009,0.729,0.108,1.018,0.361
	c0.38,0.344,0.579,0.724,0.602,1.138c0.022,0.412-0.156,0.769-0.534,1.067c-0.757,0.621-1.697,1.124-2.82,1.517
	C233.568,69.1,232.495,69.294,231.473,69.294z"
          />
        </svg>
      </router-link>
      <div
        class="w-[60%] ml-8 xl:min-w-[45%] xl:max-w-[55%] hidden xl:flex justify-between text-xl font-medium"
      >
        <ul class="flex justify-between w-full">
          <li v-for="item in items" :key="item.name" class="relative my-auto">
           <router-link
    :to="{ path: item.path || '/', hash: item.url }"
    class="flex relative animation cursor-pointer capitalize"
    :class="[item.class]"
    @mouseover="openDropdown(item)"
    @mouseleave="closeDropdown(item)"
  >
    <span class="flex flex-row items-center">
      {{ item.name }}
      <div v-if="item.submenu">
        <svg v-if="item.showDropdown" xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 24 24"><path fill="currentColor" d="M12 14.379q-.161 0-.298-.053t-.267-.184L7.046 9.754q-.14-.14-.15-.344t.15-.364t.354-.16t.354.16L12 13.292l4.246-4.246q.14-.14.345-.15q.203-.01.363.15t.16.354t-.16.354l-4.389 4.388q-.13.131-.267.184q-.136.053-.298.053"/></svg>
        <svg v-else xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 24 24"><path fill="currentColor" d="M14.379 12q0 .161-.053.298t-.184.267L9.754 16.954q-.14.14-.344.15t-.364-.15t-.16-.354t.16-.354L13.292 12L9.046 7.754q-.14-.14-.15-.345q-.01-.203.15-.363t.354-.16t.354.16l4.388 4.389q.131.13.184.267q.053.136.053.298"/></svg>

        </div>
    </span>

    <div
      v-if="item.submenu && item.showDropdown"
      class="absolute w-[270px] top-full left-0"
    >
      <ul class="bg-[#00367c] p-3 rounded-md shadow-md">
        <li
          class="p-3"
          v-for="submenuItem in item.submenu"
          :key="submenuItem.name"
        >
          <router-link class="animation" :to="submenuItem.path">{{
            submenuItem.name
          }}</router-link>
        </li>
      </ul>
    </div>
  </router-link>
          </li>
          <li>
            <router-link to="/products">
              <div
                class="bg-[white] text-black hover:text-black transition-all duration-550 ease-in-out rounded-lg md:rounded-xl text-xl font-semibold py-[4px] md:py-3 px-6"
              >
                <button type="" class="font-extrabold">SALU</button>
              </div>
            </router-link>
          </li>
        </ul>
      </div>

      <div
        :class="{ padding1: scrolled }"
        class="phoneIcons border-[1px] md:border-2 xl:border-white hover:bg-white text-white hover:text-black transition-all duration-550 ease-in-out rounded-lg md:rounded-xl text-2xl py-[2px] md:py-2 px-2"
      >
        <a
          href="tel:+917837588185"
          class="px-2 md:px-5 y-1 text-lg flex justify-center items-center hover:text-bold focus:cursor-pointer"
        >
          <span class="phoneIcon" ref="phoneIcon">
            <svg
              class="h-4 w-4 md:w-6 md:h-6 fill-[#fff] whatsapp"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 448 512"
            >
              <path
                d="M380.9 97.1C339 55.1 283.2 32 223.9 32c-122.4 0-222 99.6-222 222 0 39.1 10.2 77.3 29.6 111L0 480l117.7-30.9c32.4 17.7 68.9 27 106.1 27h.1c122.3 0 224.1-99.6 224.1-222 0-59.3-25.2-115-67.1-157zm-157 341.6c-33.2 0-65.7-8.9-94-25.7l-6.7-4-69.8 18.3L72 359.2l-4.4-7c-18.5-29.4-28.2-63.3-28.2-98.2 0-101.7 82.8-184.5 184.6-184.5 49.3 0 95.6 19.2 130.4 54.1 34.8 34.9 56.2 81.2 56.1 130.5 0 101.8-84.9 184.6-186.6 184.6zm101.2-138.2c-5.5-2.8-32.8-16.2-37.9-18-5.1-1.9-8.8-2.8-12.5 2.8-3.7 5.6-14.3 18-17.6 21.8-3.2 3.7-6.5 4.2-12 1.4-32.6-16.3-54-29.1-75.5-66-5.7-9.8 5.7-9.1 16.3-30.3 1.8-3.7.9-6.9-.5-9.7-1.4-2.8-12.5-30.1-17.1-41.2-4.5-10.8-9.1-9.3-12.5-9.5-3.2-.2-6.9-.2-10.6-.2-3.7 0-9.7 1.4-14.8 6.9-5.1 5.6-19.4 19-19.4 46.3 0 27.3 19.9 53.7 22.6 57.4 2.8 3.7 39.1 59.7 94.8 83.8 35.2 15.2 49 16.5 66.6 13.9 10.7-1.6 32.8-13.4 37.4-26.4 4.6-13 4.6-24.1 3.2-26.4-1.3-2.5-5-3.9-10.5-6.6z"
              />
            </svg>
          </span>

          <span
            :class="{ padding1: scrolled }"
            class="ml-1 sm:ml-2 md:ml-4 text-xs sm:text-base my-1 sm:my-0"
            >+91 78 3758 8185</span
          >
        </a>
      </div>

      <div
        id="mySidenav"
        :class="{ hidden: !showSideNav }"
        class="sidenav w-[100vw] overlay1 xl:hidden black-overlay text-lg md:text-2xl flex justify-between"
      >
        <div @click="closeNav()" class="w-[45vw]"></div>
        <div id="sideNav-left" class="sidenav1 text-white">
          <div class="mx-12 mt-8 flex justify-end items-center">
            <button class="" @click="closeNav()">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="w-[20px] h-[20px] md:w-[30px] sm:h-[30px] fill-[#fff]"
                viewBox="0 0 320 512"
              >
                <path
                  d="M310.6 361.4c12.5 12.5 12.5 32.75 0 45.25C304.4 412.9 296.2 416 288 416s-16.38-3.125-22.62-9.375L160 301.3L54.63 406.6C48.38 412.9 40.19 416 32 416S15.63 412.9 9.375 406.6c-12.5-12.5-12.5-32.75 0-45.25l105.4-105.4L9.375 150.6c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0L160 210.8l105.4-105.4c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25l-105.4 105.4L310.6 361.4z"
                />
              </svg>
            </button>
          </div>
          <div class="pl-5 pt-8 md:pt-12 text-xl md:text-2xl">
            <ul>
              <li
                v-for="item in items"
                id="nav-link"
                :key="item.name"
                class="text-left list-none"
              >
                <!-- <router-link
                :to="{ path: item.path || '/', hash: item.url }"
                class="flex cursor-pointer capitalize pb-4 md:mb-6"
                :class="[item.class]"
              >
                {{ item.name }}
              </router-link> -->
                <router-link
                  :to="{ path: item.path || '/', hash: item.url }"
                  class="flex flex-col relative cursor-pointer capitalize pb-4 md:mb-6"
                  :class="[item.class]"
                  @click="item.showDropdown = true;"
                >
                <span class="flex flex-row items-center">
      {{ item.name }}
      <div v-if="item.submenu">
        <svg v-if="item.showDropdown" xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 24 24"><path fill="currentColor" d="M12 14.379q-.161 0-.298-.053t-.267-.184L7.046 9.754q-.14-.14-.15-.344t.15-.364t.354-.16t.354.16L12 13.292l4.246-4.246q.14-.14.345-.15q.203-.01.363.15t.16.354t-.16.354l-4.389 4.388q-.13.131-.267.184q-.136.053-.298.053"/></svg>
        <svg v-else xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 24 24"><path fill="currentColor" d="M14.379 12q0 .161-.053.298t-.184.267L9.754 16.954q-.14.14-.344.15t-.364-.15t-.16-.354t.16-.354L13.292 12L9.046 7.754q-.14-.14-.15-.345q-.01-.203.15-.363t.354-.16t.354.16l4.388 4.389q.131.13.184.267q.053.136.053.298"/></svg>

        </div>
    </span>
                  <div
                    v-if="item.submenu && item.showDropdown"
                    class="flex-col translate-y-2 bg-transparent w-[270px] mt-2"
                  >
                    <ul class="bg-[#00367c] p-3 rounded-md shadow-md">
                      <li
                        class="p-3 text-base"
                        v-for="submenuItem in item.submenu"
                        :key="submenuItem.name"
                      >
                        <router-link class="animation" :to="submenuItem.path">{{
                          submenuItem.name
                        }}</router-link>
                      </li>
                    </ul>
                  </div>
                </router-link>
              </li>

              <li>
                <router-link to="/products">
                  <button
                    type=""
                    class="bg-white text-black hover:text-black transition-all duration-550 ease-in-out rounded-lg md:rounded-xl text-xl font-semibold py-[4px] md:py-3 px-6"
                  >
                    SALU
                  </button></router-link
                >
              </li>
            </ul>
          </div>
        </div>
      </div>
      <svg
        @click="openNav()"
        class="w-6 h-5 sm:h-8 sm:w-6 xl:w-10 xl:h-12 fill-[#fff] text-white xl:hidden cursor-pointer"
        fill="none"
        stroke="currentColor"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          d="M4 6h16M4 12h16M4 18h16"
        ></path>
      </svg>
    </div>
  </section>
</template>

<script>
export default {
  name: "NavBar",
  async created() {
    window.addEventListener("scroll", this.handleScroll);
  },
  data() {
    return {
      scrolled: false,
      showSideNav: false,
      currentPage: location.href,
      items: [
        { name: "home", path: "/", class: "home" },
        { name: "about", path: "/aboutus", class: "about" },
        {
          name: "Services",
          path: "/services",
          class: "Services",
          submenu: [
            { name: "Design", path: "/design" },
            { name: "Development", path: "/development" },
            {
              name: "Artificial Intelligence",
              path: "/artificial-intelligence",
            },
            { name: "Cloud Computing", path: "/cloud-computing" },
            { name: "Quallty Engineering", path: "/quality-engineering" },
            { name: "DevOps", path: "/dev-operations" },
          ],
        },
        { name: "Technologies", path: "/technologies", class: "technologies" },
        { name: "Contact", path: "/contact", class: "contact" },
      ],
    };
  },
  methods: {
    handleScroll() {
      this.scrolled = scrollY > 60;
    },
    openNav() {
      this.showSideNav = true;
      setTimeout(() => {
        document.getElementById("sideNav-left").style.width = "70vw";
      }, 1);
    },

    closeNav() {
      setTimeout(() => {
        this.showSideNav = false;
      }, 200);
      document.getElementById("sideNav-left").style.width = "0px";
    },
    openDropdown(item) {
      item.showDropdown = true;
    },
    closeDropdown(item) {
      item.showDropdown = false;
    },
    toggleDropdown(item) {
      item.showDropdown = !item.showDropdown;
    },
  },
};
</script>

<style scoped>
#sideNav-left {
  width: 0px;
  height: 100%;
  position: fixed;
  width: 0px;
  z-index: 100;
  top: 0;
  right: 0;
  color: white;
  transition: 0.5s;
}

.animation {
  color: #fff;
  text-decoration: none;
  display: inline-block;
  position: relative;
}

.animation:after {
  background: none repeat scroll 0 0 transparent;
  bottom: -3px;
  content: "";
  display: block;
  height: 1.5px;
  left: 50%;
  position: absolute;
  background: #fff;
  transition: width 0.4s linear 0s, left 0.4s linear 0s;
  width: 0;
}

.animation:hover:after {
  width: 100%;
  left: 0;
}

.sidenav {
  height: 100%;
  position: fixed;
  z-index: 100;
  top: 0;
  right: 0;
  transition: 0.5s;
}

.sidenav1 {
  backdrop-filter: blur(10px) saturate(200%);
  -webkit-backdrop-filter: blur(10px) saturate(200%);
  background-color: rgba(0, 53, 124, 0.8);
  color: white;
}

.logo {
  transition: all ease-in-out 0.5s;
}

.dropdown {
  position: relative;
}

.dropdown-trigger {
  cursor: pointer;
  display: inline-block;
}

.dropdown-content {
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  height: 100px;
  background-color: #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.dropdown-trigger:hover + .dropdown-content,
.dropdown-content:hover {
  display: block;
}

.shake {
  animation: shake 6s cubic-bezier(0.36, 0.07, 0.19, 0.97) infinite;
  transform: translate3d(0, 0, 0);
}

@keyframes shake {
  10% {
    transform: translate3d(-1px, 0, 0);
  }

  15% {
    transform: translate3d(2px, 0, 0);
  }

  20% {
    transform: translate3d(-4px, 0, 0);
  }

  25% {
    transform: translate3d(4px, 0, 0);
  }

  30%,
  100% {
    transform: translate3d(0px, 0, 0);
  }
}

@keyframes shake {
  from {
    transform: rotate3d(0, 0, 1, 0deg);
  }

  20%,
  32%,
  44%,
  56%,
  68% {
    transform: rotate3d(0, 0, 1, 0deg);
  }

  23%,
  35%,
  47%,
  59%,
  71% {
    transform: rotate3d(0, 0, 1, 15deg);
  }

  26%,
  38%,
  50%,
  62%,
  74% {
    transform: rotate3d(0, 0, 1, 0deg);
  }

  29%,
  41%,
  53%,
  65%,
  77% {
    transform: rotate3d(0, 0, 1, -15deg);
  }

  80% {
    transform: rotate3d(0, 0, 1, 0deg);
  }
}

.phoneIcons:hover .whatsapp {
  fill: black;
}

.phoneIcons {
  transition: all ease-in-out 0.5s;
}

.phoneIcons:hover .phoneIcon {
  animation-name: shake;
  animation-duration: 1.5s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

.router-link-exact-active {
  color: rgb(245, 56, 56);
}

.navbar {
  position: -webkit-sticky;
  position: sticky;
  transition: padding 0.4s linear;
  top: 0;
}

.overlay {
  background: #c33764;
  /* fallback for old browsers */
  background: -webkit-linear-gradient(to right, #1d2671, #c33764);
  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right, #1d2671, #c33764);
  /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

  transition: 0.8s ease-in-out;
}

.img {
  width: 150px;
  transition: all 0.5s ease-in-out;
}

@media (max-width: 1250px) {
  .img {
    width: 130px;
    transition: all 0.5s ease-in-out;
  }

  .padding {
    padding: 15px;
  }
}

@media (max-width: 600px) {
  .img {
    width: 90px;
    transition: all 0.5s ease-in-out;
  }
}

.padding1 {
  padding: 2px;
}

.overlay1 {
  background-color: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(2px);
  -webkit-backdrop-filter: blur(2px);
}
</style>
