<template>
  <Navbar />
  <div class="w-full lg:h-[65vh] relative">
    <div class="w-full h-full z-10">
      <div class="bg-color bg-black h-full">
        <img
          class="w-full min-h-[45vh] lg:h-full object-cover object-top background-image"
          :src="require('../../assets/' + headerImage)"
        />
        <div
          class="absolute top-0 w-full h-full bg-color flex justify-center items-end"
        >
          <div
            class="w-full bg-gradient-to-b from-transparent to-[#000000] absolute text-white mx-auto"
          >
            <div class="w-[90%] md:w-[90%] mx-auto mb-8">
              <div class="w-full 2xl:w-[70%]">
                <h1
                  class="text-xl sm:text-3xl md:text-4xl xl:text-6xl font-extrabold xl:leading-snug"
                >
                  {{ headerTitle }}
                </h1>
                <span
                  class="text-sm tracking-tighter sm:text-base lg:text-xl lg:font-extrabold mt-4 xl:mt-4 block"
                >
                  {{ headerDescription }}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="bg-gray-100">
    <div class="mx-auto w-[90%] md:w-[85%] pt-10">
      <div class="flex items-center overflow-y-auto whitespace-nowrap">
        <a href="#" class="text-gray-600">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="w-5 h-5"
            viewBox="0 0 20 20"
            fill="#fafafa"
          >
            <path
              d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z"
            />
          </svg>

          <span
            v-for="(item, index) in breadcrumb"
            :key="index"
            class="text-lg"
          >
            <a href="/">
              <span
                class="text-blue-400 hover:underline"
                :class="{ 'text-red-800': isCurrentPage(index) }"
              >
                {{ item }}
              </span>
            </a>
            <span v-if="!isCurrentPage(index)" class="mx-1 text-blue-400"
              >/</span
            >
          </span>
        </a>
      </div>
    </div>
  </div>
  <slot></slot>

  <Footer :bgFooter="bgFooter" />
</template>

<script>
import Navbar from "../../components/Navbar.vue";
import Footer from "../../components/Home/Footer.vue";
export default {
  name: "AppLayout",
  components: {
    Navbar,
    Footer,
  },
  computed: {
    isCurrentPage() {
      return (index) => {
        return index === this.breadcrumb.length - 1;
      };
    },
  },
  props: {
    bgFooter: {
      type: String,
      required: false,
      default: "bg-gray-100",
    },
    headerTitle: {
      type: String,
      required: false,
    },
    headerDescription: {
      type: String,
      required: false,
    },
    breadcrumb: {
      type: Array,
      required: false,
    },
    headerImage: {
      type: String,
    },
  },
};
</script>

<style scoped>
.background-image {
  object-fit: cover;
  object-position: left right;
}
.bg-color {
  background-color: rgba(0, 0, 0, 0.7);
  color: white;
}
</style>
