import { createRouter, createWebHistory } from 'vue-router';

const HomePage = () => import(/* webpackChunkName: "home" */ '../Views/Home.vue');
const Contact = () => import(/* webpackChunkName: "contact" */ '../Views/ContactUs.vue');
const AboutUS = () => import(/* webpackChunkName: "aboutus" */ '../Views/AboutUS.vue');
const Services = () => import(/* webpackChunkName: "services" */ '../Views/Services.vue');
const Technologies = () => import(/* webpackChunkName: "technologies" */ '../Views/Technologies.vue');
const OurProducts = () => import(/* webpackChunkName: "ourproducts" */ '../Views/OurProducts.vue');
const DevelopmentService = () => import(/* webpackChunkName: "development" */ '../Views/ServicePages/DevelopmentService.vue');
const DesignService = () => import(/* webpackChunkName: "design" */ '../Views/ServicePages/DesignService.vue');
const DevOpsService = () => import(/* webpackChunkName: "devops" */ '../Views/ServicePages/DevOpsService.vue');
const ArtificialIntelligenceService = () => import(/* webpackChunkName: "ai" */ '../Views/ServicePages/ArtificialIntelligenceService.vue');
const QualityEngineeringService = () => import(/* webpackChunkName: "quality" */ '../Views/ServicePages/QualityEngineeringService.vue');
const CloudComputingService = () => import(/* webpackChunkName: "cloud" */ '../Views/ServicePages/CloudComputingService.vue');
const NotFound = () => import(/* webpackChunkName: "notfound" */ '../Views/NotFound.vue');
const PrivacyPolicy = () => import(/* webpackChunkName: "privacy" */ '../Views/PrivacyPolicy.vue');

export const routes = [
  {
    path: '/',
    name: 'home',
    component: HomePage,
    meta: {
      title: 'Best Software Development Company in India | Algoson Software',
      description: 'Algoson Software is the best software development company in India, offering quality services in the USA, Canada, Europe, and beyond. Quality solutions worldwide.',
      keywords: 'leading software development company, customized software product, software development company, custom software development, software development company near me, startup software development, viewJs, reactjs, java software developers near me, Algoson software services in Switzerland, best software development company websites, website development company, agile development services, certified java developer',
    },
  },
  {
    path: '/aboutus',
    name: 'AboutUS',
    component: AboutUS,
    meta: {
      title: 'Custom Application Development Company | Algoson Software',
      description: 'Are you looking for a custom application development company in India? Algoson Software offers quality, affordable solutions for your apps, websites, and software.',
      keywords: 'local software developers, custom software development company, custom software development services, custom software development firms, application development companies, web development company, Algoson software services in Canada, custom web application development, python web development services, agile software development and devops, certified python and javascript  developer',
    },
  },
  {
    path: '/contact',
    name: 'Contact',
    component: Contact,
    meta: {
      title: 'Website App and Software Development Company | Algoson',
      description: 'Algoson Software is a top website, app, and software development company in Mohali, India. Explore to learn more about our services. For services, dial +91 78 375 8185.',
      keywords: 'it software development company, best software developers near me, custom software development near me, hire best viewJs and reactjs expert, hire Algoson for best service in USA, Canada, France, Portugal, Italy, Switzerland and Germany, agile software development services, custom designed software company in India, app development websites',
    },
  },
  {
    path: '/services',
    name: 'Services',
    component: Services,
    meta: {
      title: 'Best Custom Software Development Company | Algoson Software',
      description: 'Algoson Software, the best custom software development company in India, offers services in the USA, Canada, Switzerland, Portugal, France, Germany, and Italy.',
      keywords: 'low cost software development, software development, software development services, software design company, software product development company, Algoson software services in  Portugal, Algoson software services in USA, web application development company, java  and web development, web software development services, certified viewJs and reactjs developer',
    },
  },
  {
    path: '/development',
    name: 'DevelopmentService',
    component: DevelopmentService,
    meta: {
      title: 'Web App Development Services in Mohali | Algoson Software',
      description: 'Algoson is the top web and app development services company in Mohali. We offer services globally and provide affordable solutions. Contact us at +91 78 3758 8185.',
      keywords: 'building website for business, web development, software development near me, web application development, custom software for local business, full stack web development service, Algoson software services in Germany, IT applications development, website and application development company',
    },
  },
  {
    path: '/design',
    name: 'DesignService',
    component: DesignService,
    meta: {
      title: 'Best Web and Software Development in India | Algoson',
      description: 'We are the best web and software developers in India, offering quality and affordable solutions worldwide. Explore Algoson Software for more information.',
      keywords: 'software designers near me, software design and development company, web and mobile application development services, javascript web programming, Algoson software services in France, best technologies for web development, build custom website, website design and development company',
    },
  },
  {
    path: '/quality-engineering',
    name: 'QualityEngineeringService',
    component: QualityEngineeringService,
    meta: {
      title: 'Explore Algoson Quality Engineering Services | Dial +91 78 3758 8185',
      description: 'We are experts in delivering quality engineering services globally. Explore Algoson Software here, and dial +91 78 3758 8185 for more information.',
      keywords: 'new software development technologies, backend technologies for web development, build web application, website design and development services, custom software developer, agile development technologies',
    },
  },
  {
    path: '/dev-operations',
    name: 'DevOpsService',
    component: DevOpsService,
    meta: {
      title: 'Browse DevOps Services by Algoson | DevOps Services',
      description: 'We are the top DevOps service provider globally. Algoson Software, a leading company in India, offers services at affordable prices. Click here for more information.',
      keywords: 'software development services near me, startup software development company, web development services company, software application development company, website and app development, best software development services',
    },
  },
  {
    path: '/artificial-intelligence',
    name: 'ArtificialIntelligenceService',
    component: ArtificialIntelligenceService,
    meta: {
      title: 'Best Artificial Intelligence Services Company | Dial +91 78 3758 8185',
      description: 'Algoson Software is the best platform for artificial intelligence services. Click here to hire the best AI and machine learning solutions at affordable prices.',
      keywords: 'web application company, custom web application development services, software product development service, artificial intelligence ai, Algoson software services in Milan, high quality software development, ai machine learning technologies for software development, certified machine learning team, certified AI professional',
    },
  },
  {
    path: '/cloud-computing',
    name: 'CloudComputingService',
    component: CloudComputingService,
    meta: {
      title: 'Algoson | Best Cloud Computing Services',
      description: 'Algoson Software delivers the best cloud computing services in India. Are you searching for a company that delivers quality services? Discover Algoson Software here.',
      keywords: 'backend development services, AI and machine learning platform, custom software application development services, application software engineer, product and software development',
    },
  },
  {
    path: '/technologies',
    name: 'Technologies',
    component: Technologies,
    meta: {
      title: 'Application Software Company | Trending in Software Development',
      description: 'Algoson Software is the best website, application, and software development company in Mohali, India. Let\'s connect with what\'s trending in software development.',
      keywords: 'custom application development, customer software development, best software development, web application development services, hire certified java experts, latest technologies in software development, trending technologies in software development, web development technologies',
    },
  },
  {
    path: '/products',
    name: 'products',
    component: OurProducts,
    meta: {
      title: 'Products',
      description: 'Delivering Design and Development Mix to your Business Needs. Transforming Businesses with Powerful Software for 5+ years.',
      keywords: 'Software solution company, customized software solution',
    },
  },
  {
    path: '/privacy-policy',
    name: 'privacyPolicy',
    component: PrivacyPolicy,
    meta: {
      title: 'Privacy Policy',
      description: 'Privacy policy',
      keywords: 'Software solution company, customized software solution',
    },
  },
  {
    path: '/:catchAll(.*)',
    name: 'NotFound',
    component: NotFound,
  },
];

// const router = createRouter({
//   history: createMemoryHistory(),
//   routes,
// });


const router = createRouter({
  history: createWebHistory(),
  scrollBehavior(to) {
    if (to.hash) {
      setTimeout(() => {
        window.scrollTo({
          top: document.querySelector(to.hash).offsetTop - 250,
          behavior: "smooth",
        });
      }, 10);
    } else {
      return {
        x: 0,
        y: 0,
      };
    }  
  },
  routes,
});
router.beforeEach((to, from, next) => {
  if (to.fullPath.substr(0, 2) !== "/#") {
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 10);
  }
  if (to.meta.title) {
    document.title = `${to.meta.title} `;
    document.getElementsByTagName("meta")["description"].content =
      to.meta.description;
    document.getElementsByTagName("meta")["keywords"].content =
      to.meta.keywords;
  } else {
    document.title = "One Stop Leading Software Solutions- Algoson Software";
    document.getElementsByTagName("meta")["description"].content =
      "Delivering Design and Development Mix to your Business Needs.Transforming Businesses with Powerful Software for 5+ years.You can call us on +917837588185 or email us on ms@algoson.com.";
    document.getElementsByTagName("meta")["keywords"].content =
      "Software solution company,customized software solution,web designing,Mobile Development, web development, machine learning, quality assurance,cloud computing,dev operation";
  }
  next();
});
export default router;
